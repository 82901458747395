import { InjectionKey } from "vue";
import { createStore, Store } from "vuex";
import auth from './modules/auth';
import talentStore, {
  TalentState,
  actionTypes as talentActionTypes,
  mutationTypes as talentMutationTypes,
} from "./modules/talent";
import userStore, {
  UserState,
  actionTypes as userActionTypes,
  mutationTypes as userMutationTypes,
} from "./modules/user";

export type RootState = {
  user: UserState;
  talent: TalentState;
  auth: {
    token: string | null;
    userRoles: string[];
  };
};

export const actionTypes = {
  user: userActionTypes,
  talent: talentActionTypes,
};

export const mutationTypes = {
  user: userMutationTypes,
  talent: talentMutationTypes,
};

export const store = createStore<RootState>({
  modules: {
    auth,
    user: userStore,
    talent: talentStore,
  },
});

export const storeKey: InjectionKey<Store<RootState>> = Symbol();
