<template>
  <ion-page>
    <ion-content>
      <ion-refresher slot="fixed" class="refresher" :disabled="!!loadError || hasDeals?.isLoading || isDataLoading"
        @ionRefresh="doRefresh($event)">
        <ion-refresher-content> </ion-refresher-content>
      </ion-refresher>
      <empty-message v-if="!!loadError" @reload="loadData" :error="{
        error: loadError,
        showRetryButton: true,
      }"></empty-message>
      <template v-else>
        <ion-popover class="range-popover" trigger="range-popover" :dismiss-on-select="true">
          <ion-list>
            <template v-for="(group, groupIndex) in dateRanges" :key="groupIndex">
              <ion-item v-for="(range, index) in group" :key="range.label" :button="true" :detail="false"
                :lines="index === dateRanges.length - 1 ? 'none' : 'full'" @click="dateRangeChangeHandler(range)">
                {{ range.label }}
              </ion-item>
              <div class="range-popover__divider" v-if="groupIndex !== dateRanges.length - 1" />
            </template>
          </ion-list>
        </ion-popover>
        <div class="header">
          <div class="header__greeting">
            {{ t("common.hello") }}
            <div class="header__name">
              <ion-skeleton-text v-if="!talent?.data?.name" animated />
              {{ talent?.data?.name }}
            </div>
          </div>
          <div class="header__range-popover" id="range-popover" v-if="!isTalentAssistant">
            <template v-if="hasDeals?.data === true">
              {{ selectedDateRange.label }}
              <font-awesome-icon class="header__range-icon" :icon="['fas', 'chevron-down']" />
            </template>
          </div>
        </div>
        <no-deals-placeholder v-if="hasDeals?.data === false" />
        <template v-else>
          <div class="earnings" v-if="!isTalentAssistant">
            <div class="earnings__block" @click="navigateRevenue">
              <div class="earnings__amount">
                <ion-skeleton-text style="height: 26px; width: 50%" v-if="isDataLoading || hasDeals?.isLoading"
                  animated />
                <template v-else>
                  {{ statistics?.revenues }} <sup class="earnings__label">{{ t("common.all-taxes-included") }}</sup>
                </template>
              </div>
              <div class="earnings__label">{{ t("common.paid") }}</div>
            </div>
            <div :class="{
              earnings__block: true,
              'earnings__block--pending': !!statistics?.pendingRevenues,
            }" @click="navigatePendingInvoices">
              <div class="earnings__amount">
                <ion-skeleton-text style="height: 26px; width: 50%" v-if="isDataLoading || hasDeals?.isLoading"
                  animated />
                <template v-else>
                  {{ statistics?.pendingRevenues }} <sup class="earnings__label">{{ t("common.all-taxes-included")
                    }}</sup>
                </template>
              </div>
              <div class="earnings__label">
                {{
                  t("common.pending")
                }}
              </div>
            </div>
          </div>
          <div class="calendar">
            <Calendar :title="t('home-page.todo')" :calendarEvents="calendarEvents"
              :isLoading="isDataLoading || hasDeals?.isLoading" />
          </div>

        </template>
      </template>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  IonPopover,
  IonList,
  IonItem,
  modalController,
  IonSkeletonText,
  IonRefresher,
  IonRefresherContent,
  useIonRouter,
  isPlatform,
  onIonViewDidEnter
} from "@ionic/vue";
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  Ref,
  ref,
  watch,
} from "vue";
import Calendar from "@/components/Calendar.vue";
import InvoiceModal from "@/modals/Invoice.vue";
import { AppColor } from "@/types";
import { useUserTalent, useUserTalentHasDeals } from "@/hooks/talent";
import { DateTime } from "luxon";
import errorHandlerService from "@/services/error-handler.service";
import dealsService from "@/services/deals.service";
import { Invoice } from "@/types/deals";
import { formatPrice, invoiceStatusDetails } from "@/utils";
import EmptyMessage from "@/components/EmptyMessage.vue";
import { useI18n } from "vue-i18n";
import NoDealsPlaceholder from "@/components/NoDealsPlaceholder.vue";
import userService from "@/services/user.service";
import localeService from "@/services/locale.service";
import apiService from "@/services/api.service";
import { HydraListResponse } from "@/types/api";
import { CalendarEvent } from "@/types/talent";
import { useAuth } from '@/composables/useAuth'
import NotificationsAlert from "@/modals/NotificationsAlert.vue";
import notificationsService, {
  NotificationsInfo,
} from "@/services/notifications.service";

type DateRangeItem = {
  from?: string;
  to?: string;
  label: string;
};

type InvoiceItem = {
  key: string;
  title: string;
  subTitle: string;
  badge: {
    text: string;
    color: AppColor;
  };
  rightDescription: string;
  invoice: Invoice;
  image?: string | null;
};

export default defineComponent({
  name: "HomePage",
  components: {
    IonContent,
    IonPage,
    Calendar,
    IonPopover,
    IonItem,
    IonList,
    IonSkeletonText,
    EmptyMessage,
    NoDealsPlaceholder,
    IonRefresher,
    IonRefresherContent,
  },
  methods: {
    forceUpdate() {
      this.$forceUpdate();
    },
  },
  setup() {
    const { t, locale } = useI18n();
    const talent = useUserTalent();
    const notificationsInfo = ref<NotificationsInfo>();
    const calendarEvents = ref<CalendarEvent[]>([]);
    const hasDeals = useUserTalentHasDeals();
    const { isTalentAssistant } = useAuth()
    onIonViewDidEnter(() => {
      notificationsService.getNotificationsInfo().then((info) => {
        notificationsInfo.value = info;
      });
    });

    const getDateRanges = () => [
      [
        {
          from: DateTime.now().startOf("week").toISO(),
          label: t("datetime.current-week", { count: 30 }, 30),
        },
        {
          from: DateTime.now().startOf("month").toISO(),
          label: t("datetime.current-month", { count: 6 }, 6),
        },
        {
          from: DateTime.now().startOf("year").toISO(),
          label: t("datetime.current-year", { count: 1 }, 1),
        },
      ],
      [
        {
          from: DateTime.now().minus({ days: 30 }).startOf("day").toISO(),
          label: t("datetime.days", { count: 30 }, 30),
        },
        {
          from: DateTime.now().minus({ months: 6 }).startOf("day").toISO(),
          label: t("datetime.months", { count: 6 }, 6),
        },
        {
          from: DateTime.now().minus({ years: 1 }).startOf("day").toISO(),
          label: t("datetime.years", { count: 1 }, 1),
        },
      ],
      [
        {
          from: DateTime.now().minus({ months: 1 }).startOf("month").toISO(),
          to: DateTime.now().minus({ months: 1 }).endOf("month").toISO(),
          label: DateTime.now().minus({ months: 1 }).toFormat("LLLL"),
        },
        {
          from: DateTime.now().minus({ months: 2 }).startOf("month").toISO(),
          to: DateTime.now().minus({ months: 2 }).endOf("month").toISO(),
          label: DateTime.now().minus({ months: 2 }).toFormat("LLLL"),
        },
        {
          from: DateTime.now().minus({ months: 3 }).startOf("month").toISO(),
          to: DateTime.now().minus({ months: 3 }).endOf("month").toISO(),
          label: DateTime.now().minus({ months: 3 }).toFormat("LLLL"),
        },
      ],
      [
        {
          from: DateTime.now().minus({ years: 1 }).startOf("year").toISO(),
          to: DateTime.now().minus({ years: 1 }).endOf("year").toISO(),
          label: DateTime.now().minus({ years: 1 }).year.toString(),
        },
        {
          from: DateTime.now().minus({ years: 2 }).startOf("year").toISO(),
          to: DateTime.now().minus({ years: 2 }).endOf("year").toISO(),
          label: DateTime.now().minus({ years: 2 }).year.toString(),
        },
        {
          from: DateTime.now().minus({ years: 3 }).startOf("year").toISO(),
          to: DateTime.now().minus({ years: 3 }).endOf("year").toISO(),
          label: DateTime.now().minus({ years: 3 }).year.toString(),
        },
      ],
      [
        {
          date: undefined,
          label: t("datetime.anytime"),
        },
      ],
    ];


    const dateRanges: Ref<DateRangeItem[][]> = ref(getDateRanges());

    const isDataLoading = ref<boolean>(true);
    const invoiceItems = ref<InvoiceItem[]>([]);
    const statistics = ref<{
      revenues: string;
      deals: number;
      pendingRevenues?: string;
    }>();
    const selectedDateRange = ref<DateRangeItem>(dateRanges.value[4][0]);
    const loadError = ref<any | false>();
    const router = useIonRouter();

    const loadData = async () => {
      if (loadError.value) {
        loadError.value = false;
        isDataLoading.value = true;
      }
      try {
        let insights = null;
        if (!isTalentAssistant.value) {
          insights = await userService.getInsights({
            startDate: selectedDateRange.value.from,
            endDate:
              selectedDateRange.value.to || DateTime.now().endOf("day").toISO(),
          });
        }
        const deals = (
          await dealsService.fetchDeals({
            "createdAt[after]": selectedDateRange.value.from,
            "createdAt[before]": selectedDateRange.value.to,
          })
        )["hydra:member"];
        calendarEvents.value = (
          await apiService.get<HydraListResponse<CalendarEvent>>(
            "calendar_events",
            {
              params: {
                "startDate[after]": DateTime.now()
                  .minus({ days: 7 })
                  .startOf("day")
                  .toISO(),
              },
            }
          )
        ).data["hydra:member"];
        if (deals.length) {
          const invoicesResp = await dealsService.fetchInvoices({
            "order[issuedAt]": "desc",
            "issuedAt[after]": selectedDateRange.value.from,
            "issuedAt[before]": selectedDateRange.value.to,
          });
          const invoices = invoicesResp["hydra:member"];
          invoiceItems.value = invoices.map((item) => ({
            key: item["@id"],
            invoice: item,
            title: item.deal?.brand?.name,
            subTitle: formatPrice(
              item.totalBeforeTax +
              Math.round(item.totalBeforeTax * (item.taxPercentage / 100)),
              item.currency
            ),
            badge: invoiceStatusDetails(item.status),
            rightDescription: DateTime.fromISO(item.issuedAt)
              .setLocale(localeService.selectedLocale)
              .toLocaleString({ month: "short", day: "numeric" }),
            image:
              item.deal?.contractingCompany?.mediaLogo?.readPublicLink || null,
          }));
        }
        if (!isTalentAssistant.value && insights) {
          statistics.value = {
            deals: insights.dealsCount,
            pendingRevenues: formatPrice(insights.totalInvoicedPendingWithTax),
            revenues: formatPrice(insights.totalInvoicedPaidWithTax),
          };
        }

      } catch (error: any) {
        loadError.value = error;
        errorHandlerService.handleApiError(error);
      } finally {
        isDataLoading.value = false;
      }
    };

    onMounted(async () => {
      await loadData();
      if (isPlatform("capacitor") && !notificationsInfo?.value?.enabled) {
        const modal = await modalController.create({
          component: NotificationsAlert,
          cssClass: "loopin-modal",
          handle: false,
        });
        await modal.present();
      }

    });

    const instance = getCurrentInstance();
    watch(locale, async () => {
      await loadData();
      instance?.proxy?.$forceUpdate();
      dateRanges.value = getDateRanges();
      const plainDateRanges = dateRanges.value.flat();
      const newSelectedDateRange = plainDateRanges.find(
        ({ from, to }) =>
          from === selectedDateRange.value.from &&
          to === selectedDateRange.value.to
      );
      if (newSelectedDateRange) {
        selectedDateRange.value = newSelectedDateRange;
      }
    });
    return {
      t,
      loadError,
      hasDeals,
      calendarEvents,
      isDataLoading,
      statistics,
      dateRanges,
      talent,
      selectedDateRange,
      invoiceItems,
      loadData,
      isTalentAssistant,
      navigateRevenue: () => {
        router.navigate("/tabs/deals", "none", "push");
      },
      navigatePendingInvoices: () => {
        router.navigate("/tabs/home/pending-invoices");
      },
      dateRangeChangeHandler: (dateRange: DateRangeItem) => {
        isDataLoading.value = true;
        selectedDateRange.value = dateRange;
        loadData();
      },
      openInvoiceModal: async (invoice: Invoice) => {
        const modal = await modalController.create({
          component: InvoiceModal,
          componentProps: {
            invoice,
          },
          handle: false,
          cssClass: "loopin-modal",
        });
        modal.present();
      },
      doRefresh: async (event: any) => {
        try {
          await loadData();
        } finally {
          if (event?.target) {
            (event.target as any).complete();
          }
        }
      },
    };
  },
});
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: calc(var(--ion-safe-area-top) + 20px);
  padding: 0 20px;
}

.header__greeting {
  font-size: 14px;
  line-height: 26px;
  color: var(--loopin-color-gs-90);
}

.header__name {
  font-size: 21px;
  font-weight: bold;
  color: var(--ion-text-color);
}

.header__range-popover {
  color: var(--loopin-color-gs-70);
  font-size: 14px;
  line-height: 24px;
  position: relative;
  overflow: hidden;
  padding: 1px 3px;
  border-radius: 3px;
  /* border-radius overflow hack for ios browser */
  transform: translate3d(0, 0, 0);
}

.header__range-icon {
  font-size: 12px;
  margin-left: 9px;
}

.earnings {
  display: flex;
  justify-content: space-between;
  margin: 24px 20px 30px 20px;
}

.earnings__block {
  width: calc(50% - 6px);
  border-width: 3px;
  border-style: solid;
  border-radius: 16px;
  height: 124px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.earnings__block:first-child {
  border-color: var(--loopin-color-pink);
}

.earnings__block:nth-child(2) {
  border-color: var(--loopin-color-orange);
}

.earnings__block.earnings__block--pending {
  border-color: var(--loopin-color-red);
}

.earnings__amount {
  font-size: 24px;
  line-height: 34px;
  font-weight: bold;
  margin-bottom: 8px;
}

.earnings__label {
  font-size: 13px;
  line-height: 17px;
  color: rgba(var(--ion-text-color-rgb), 0.5);
}

.invoices {
  padding: 0 20px;
  margin-bottom: 50px;
}

.invoices__header {
  font-weight: bold;
  font-size: 17px;
  line-height: 26px;
  margin-bottom: 16px;
}

.invoices__content {}

.incoive-card {
  margin-bottom: 12px;
}

.range-popover {
  --width: auto;
  --min-width: 170px;
  --offset-x: -20px;
  --background: rgba(0, 0, 0, 0);
}

.range-popover ion-list {
  --ion-item-background: var(--loopin-color-gs-30);
}

.range-popover ion-item {
  white-space: nowrap;
}

.refresher {
  top: var(--ion-safe-area-top);
}

.range-popover__divider {
  height: 2px;
  background: rgba(var(--ion-text-color-rgb), 0.1);
}
</style>
