import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17d314fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "range-popover__divider"
}
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "header__greeting" }
const _hoisted_4 = { class: "header__name" }
const _hoisted_5 = {
  key: 0,
  class: "header__range-popover",
  id: "range-popover"
}
const _hoisted_6 = {
  key: 0,
  class: "earnings"
}
const _hoisted_7 = { class: "earnings__amount" }
const _hoisted_8 = { class: "earnings__label" }
const _hoisted_9 = { class: "earnings__label" }
const _hoisted_10 = { class: "earnings__amount" }
const _hoisted_11 = { class: "earnings__label" }
const _hoisted_12 = { class: "earnings__label" }
const _hoisted_13 = { class: "calendar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_empty_message = _resolveComponent("empty-message")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_popover = _resolveComponent("ion-popover")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_no_deals_placeholder = _resolveComponent("no-deals-placeholder")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher, {
            slot: "fixed",
            class: "refresher",
            disabled: !!_ctx.loadError || _ctx.hasDeals?.isLoading || _ctx.isDataLoading,
            onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.doRefresh($event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_refresher_content)
            ]),
            _: 1
          }, 8, ["disabled"]),
          (!!_ctx.loadError)
            ? (_openBlock(), _createBlock(_component_empty_message, {
                key: 0,
                onReload: _ctx.loadData,
                error: {
        error: _ctx.loadError,
        showRetryButton: true,
      }
              }, null, 8, ["onReload", "error"]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_ion_popover, {
                  class: "range-popover",
                  trigger: "range-popover",
                  "dismiss-on-select": true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_list, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dateRanges, (group, groupIndex) => {
                          return (_openBlock(), _createElementBlock(_Fragment, { key: groupIndex }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group, (range, index) => {
                              return (_openBlock(), _createBlock(_component_ion_item, {
                                key: range.label,
                                button: true,
                                detail: false,
                                lines: index === _ctx.dateRanges.length - 1 ? 'none' : 'full',
                                onClick: ($event: any) => (_ctx.dateRangeChangeHandler(range))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(range.label), 1)
                                ]),
                                _: 2
                              }, 1032, ["lines", "onClick"]))
                            }), 128)),
                            (groupIndex !== _ctx.dateRanges.length - 1)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_1))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createTextVNode(_toDisplayString(_ctx.t("common.hello")) + " ", 1),
                    _createElementVNode("div", _hoisted_4, [
                      (!_ctx.talent?.data?.name)
                        ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                            key: 0,
                            animated: ""
                          }))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(_ctx.talent?.data?.name), 1)
                    ])
                  ]),
                  (!_ctx.isTalentAssistant)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        (_ctx.hasDeals?.data === true)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createTextVNode(_toDisplayString(_ctx.selectedDateRange.label) + " ", 1),
                              _createVNode(_component_font_awesome_icon, {
                                class: "header__range-icon",
                                icon: ['fas', 'chevron-down']
                              })
                            ], 64))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.hasDeals?.data === false)
                  ? (_openBlock(), _createBlock(_component_no_deals_placeholder, { key: 0 }))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (!_ctx.isTalentAssistant)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createElementVNode("div", {
                              class: "earnings__block",
                              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.navigateRevenue && _ctx.navigateRevenue(...args)))
                            }, [
                              _createElementVNode("div", _hoisted_7, [
                                (_ctx.isDataLoading || _ctx.hasDeals?.isLoading)
                                  ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                                      key: 0,
                                      style: {"height":"26px","width":"50%"},
                                      animated: ""
                                    }))
                                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                      _createTextVNode(_toDisplayString(_ctx.statistics?.revenues) + " ", 1),
                                      _createElementVNode("sup", _hoisted_8, _toDisplayString(_ctx.t("common.all-taxes-included")), 1)
                                    ], 64))
                              ]),
                              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.t("common.paid")), 1)
                            ]),
                            _createElementVNode("div", {
                              class: _normalizeClass({
              earnings__block: true,
              'earnings__block--pending': !!_ctx.statistics?.pendingRevenues,
            }),
                              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.navigatePendingInvoices && _ctx.navigatePendingInvoices(...args)))
                            }, [
                              _createElementVNode("div", _hoisted_10, [
                                (_ctx.isDataLoading || _ctx.hasDeals?.isLoading)
                                  ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                                      key: 0,
                                      style: {"height":"26px","width":"50%"},
                                      animated: ""
                                    }))
                                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                      _createTextVNode(_toDisplayString(_ctx.statistics?.pendingRevenues) + " ", 1),
                                      _createElementVNode("sup", _hoisted_11, _toDisplayString(_ctx.t("common.all-taxes-included")), 1)
                                    ], 64))
                              ]),
                              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.t("common.pending")), 1)
                            ], 2)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_Calendar, {
                          title: _ctx.t('home-page.todo'),
                          calendarEvents: _ctx.calendarEvents,
                          isLoading: _ctx.isDataLoading || _ctx.hasDeals?.isLoading
                        }, null, 8, ["title", "calendarEvents", "isLoading"])
                      ])
                    ], 64))
              ], 64))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}