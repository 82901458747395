import localeService from "@/services/locale.service";
import { AppColor, SocialMedia } from "@/types";
import { CompanyJobType } from "@/types/company";
import { ContractStatus, InvoiceStatus, PaymentStatus } from "@/types/deals";
import { CalendarEvent, CalendarEventType } from "@/types/talent";
import { ExternalAccountService } from "@/types/user";
import { DEFAULT_CURRENCY } from "./consts";

export const invoiceStatusDetails = (
  status: InvoiceStatus
): { text: string; color: AppColor } => {
  switch (status) {
    case InvoiceStatus.DRAFT:
      return {
        text: localeService.t("common.status-draft"),
        color: AppColor.gray,
      };
    case InvoiceStatus.SENT:
      return {
        text: localeService.t("common.status-sent"),
        color: AppColor.orange,
      };
    case InvoiceStatus.PAID:
      return {
        text: localeService.t("common.status-paid"),
        color: AppColor.green,
      };
    case InvoiceStatus.INVOICE_NUMBER_PENDING:
      return {
        text: localeService.t("common.status-pending"),
        color: AppColor.orange,
      };
  }
};

export const paymentStatusDetails = (
  status: PaymentStatus
): { text: string; color: AppColor } => {
  switch (status) {
    case PaymentStatus.PENDING_ADVANCE:
      return {
        text: localeService.t("payment.pending-advance"),
        color: AppColor.orange, // Assuming yellow color for pending advance status
      };
    case PaymentStatus.PAYMENT_ON_DUE_DATE:
      return {
        text: localeService.t("payment.payment-on-due-date"),
        color: AppColor.orange, // Assuming red color for payment on due date status
      };
    // You can add more cases here if there are other payment statuses
    default:
      return {
        text: localeService.t("payment.unknown"),
        color: AppColor.orange, // Default color for unknown status
      };
  }
};

export const contractStatusDetails = (
  status: ContractStatus
): { text: string; color: AppColor } => {
  switch (status) {
    case ContractStatus.DRAFT:
      return {
        text: localeService.t("common.status-draft"),
        color: AppColor.blue,
      };
    case ContractStatus.SENT_FOR_APPROVAL:
      return {
        text: localeService.t("common.status-sent-for-approval"),
        color: AppColor.orange,
      };
    case ContractStatus.SENT_FOR_SIGNATURE:
      return {
        text: localeService.t("common.status-sent-for-signature"),
        color: AppColor.orange,
      };
    case ContractStatus.COMPLETED:
      return {
        text: localeService.t("common.status-completed"),
        color: AppColor.green,
      };
    case ContractStatus.DECLINED:
      return {
        text: localeService.t("common.status-declined"),
        color: AppColor.red,
      };
  }
};

export const getCompanyContactJobTypeString = (jobTypes: CompanyJobType[]) => {
  let jobTypeString = "";
  if (jobTypes?.length) {
    switch (jobTypes[0]) {
      case CompanyJobType.BILLING_MANAGER:
        jobTypeString = localeService.t("common.job-type-billing-manager");
        break;
      case CompanyJobType.CONTRACT_SIGNER:
        jobTypeString = localeService.t("common.job-type-contract-signer");
        break;
      case CompanyJobType.LEGAL_MANAGER:
        jobTypeString = localeService.t("common.job-type-legal-manager");
        break;
      case CompanyJobType.PROJECT_MANAGER:
        jobTypeString = localeService.t("common.job-type-project-manager");
        break;
    }
    if (jobTypes.length > 1) {
      jobTypeString = `${jobTypeString} +${jobTypes.length - 1}`;
    }
  }
  return jobTypeString;
};

const getCurrencySign = (currency: string) => {
  const locale = navigator.language || navigator.languages?.[0] || "fr-FR";
  const fallback: { [key: string]: string } = {
    USD: "$",
    EUR: "€",
    CRC: "₡",
    GBP: "£",
    ILS: "₪",
    INR: "₹",
    JPY: "¥",
    KRW: "₩",
    NGN: "₦",
    PHP: "₱",
    PLN: "zł",
    PYG: "₲",
    THB: "฿",
    UAH: "₴",
    VND: "₫",
  };
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (0)
      .toLocaleString(locale, {
        style: "currency",
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim() ||
    fallback[currency] ||
    "€"
  );
};

export const formatPrice = (amount: number, currency?: string) => {
  return `${Math.round(amount)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}${getCurrencySign(
      currency || DEFAULT_CURRENCY
    )}`;
};

export const debounce = (fn: (...args: any) => void, delay: number) => {
  let timeout: number | null = null;
  return (...args: any) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

export const calendarEventActionInfo = (
  calendarEvent: CalendarEvent
): {
  color: AppColor;
  text: string;
} => {
  switch (calendarEvent.eventType) {
    case CalendarEventType.publish:
      return {
        text: localeService.t("common.event-action-publish"),
        color: AppColor.pink,
      };
    case CalendarEventType.submit:
      return {
        text: localeService.t("common.event-action-submit"),
        color: AppColor.pink,
      };
    case CalendarEventType.script:
      return {
        text: localeService.t("common.event-action-script"),
        color: AppColor.pink,
      };
    case CalendarEventType.shoot:
      return {
        text: localeService.t("common.event-action-shoot"),
        color: AppColor.pink,
      };
  }
};

export const capitalize = (str: string) => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
};

export const makeLinksClickable = (str: string) => {
  const URLMatcher = /(?:(?:https?|ftp):\/\/)?[\w/\-?=%.]+\.[\w/\-&?=%.]+/gim;
  const withLinks = str.replace(
    URLMatcher,
    (match) => `<a href="${match}">${match}</a>`
  );
  return URLMatcher.test(str) ? withLinks : str;
};

export const parseMagicLinkParams = (
  paramsString: string
): { token: string; refresh_token: string } => {
  const arr = new DOMParser()
    .parseFromString(paramsString, "text/html")
    ?.documentElement?.textContent?.split("&");
  if (!arr) {
    throw new Error();
  }
  const obj: any = {};
  arr.forEach((item) => {
    // eslint-disable-next-line prefer-const
    let [key, value] = item.split("=");
    if (key === "access_token") {
      key = "token";
    }
    obj[key] = value;
  });
  return obj;
};

export const getExternalAccountTitle = (service: ExternalAccountService) => {
  switch (service) {
    case ExternalAccountService.google_calendar:
      return localeService.t("common.google-calendar");
    case ExternalAccountService.meta_instagram:
      return localeService.t("common.meta-instagram");
    case ExternalAccountService.google_youtube:
      return localeService.t("common.google-youtube");
  }
};

export const getExternalAccountIcon = (service: ExternalAccountService) => {
  switch (service) {
    case ExternalAccountService.google_calendar:
      return require("@/assets/social-icons/google-calendar.png");
    case ExternalAccountService.meta_instagram:
      return require("@/assets/social-icons/instagram.png");
    case ExternalAccountService.google_youtube:
      return require("@/assets/social-icons/youtube.png");
  }
};

export const getSocialMediaIcon = (socialMedia: string) => {
  switch (socialMedia) {
    case SocialMedia.facebook:
      return require("@/assets/social-icons/facebook.png");
    case SocialMedia.youtube:
      return require("@/assets/social-icons/youtube.png");
    case SocialMedia.tiktok:
      return require("@/assets/social-icons/tiktok.png");
    case SocialMedia.instagram:
      return require("@/assets/social-icons/instagram.png");
    case SocialMedia.pinterest:
      return require("@/assets/social-icons/pinterest.png");
    case SocialMedia.podcasts:
      return require("@/assets/social-icons/podcasts.png");
    case SocialMedia.snapchat:
      return require("@/assets/social-icons/snapchat.png");
    case SocialMedia.twitch:
      return require("@/assets/social-icons/twitch.png");
    case SocialMedia.twitter:
      return require("@/assets/social-icons/twitter.png");
    case SocialMedia.x:
      return require("@/assets/social-icons/x.png");
    case SocialMedia.whatsapp:
      return require("@/assets/social-icons/whatsapp.png");
  }
};

export const getQueryParameterByName = (name: string, url: string) => {
  name = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};
