import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35f130d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "logout-btn-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { class: "loopin-toolbar" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("settings-page.title")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, {
            class: "list",
            lines: "none"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, {
                class: "item",
                button: true,
                detail: false,
                "router-link": "/tabs/settings/personal-profile"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("personal-profile-page.title")) + " ", 1),
                  _createVNode(_component_font_awesome_icon, {
                    class: "item__icon",
                    slot: "end",
                    icon: ['fas', 'chevron-right']
                  })
                ]),
                _: 1
              }),
              (!_ctx.isTalentAssistant)
                ? (_openBlock(), _createBlock(_component_ion_item, {
                    key: 0,
                    class: "item",
                    button: true,
                    detail: false,
                    "router-link": "/tabs/settings/company-profile"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("company-profile-page.title")) + " ", 1),
                      _createVNode(_component_font_awesome_icon, {
                        class: "item__icon",
                        slot: "end",
                        icon: ['fas', 'chevron-right']
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (!_ctx.isTalentAssistant)
                ? (_openBlock(), _createBlock(_component_ion_item, {
                    key: 1,
                    class: "item",
                    button: true,
                    detail: false,
                    "router-link": "/tabs/settings/assistants"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("assistants-page.title")) + " ", 1),
                      _createVNode(_component_font_awesome_icon, {
                        class: "item__icon",
                        slot: "end",
                        icon: ['fas', 'chevron-right']
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_item, {
                class: "item",
                button: true,
                detail: false,
                onClick: _ctx.handleSendInvoicesClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("settings-page.send-invoices")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }),
          (!_ctx.isTalentAssistant)
            ? (_openBlock(), _createBlock(_component_ion_list, {
                key: 0,
                class: "list",
                lines: "none"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, {
                    class: "item",
                    button: true,
                    detail: false,
                    "router-link": "/tabs/settings/external-accounts/social"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("external-accounts-page.title")) + " ", 1),
                      _createVNode(_component_font_awesome_icon, {
                        class: "item__icon",
                        slot: "end",
                        icon: ['fas', 'chevron-right']
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, {
                    class: "item",
                    button: true,
                    detail: false,
                    "router-link": "/tabs/settings/external-accounts/calendar"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("common.calendar")) + " ", 1),
                      _createVNode(_component_font_awesome_icon, {
                        class: "item__icon",
                        slot: "end",
                        icon: ['fas', 'chevron-right']
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_list, {
            class: "list",
            lines: "none"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, {
                class: "item",
                button: true,
                detail: false,
                "router-link": "/tabs/settings/notifications"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("common.notifications")) + " ", 1),
                  _createVNode(_component_font_awesome_icon, {
                    class: "item__icon",
                    slot: "end",
                    icon: ['fas', 'chevron-right']
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                class: "item",
                button: true,
                detail: false,
                "router-link": "/tabs/settings/language"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("language-settings-page.title")) + " ", 1),
                  _createVNode(_component_font_awesome_icon, {
                    class: "item__icon",
                    slot: "end",
                    icon: ['fas', 'chevron-right']
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_list, {
            class: "list",
            lines: "none"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, {
                class: "item",
                button: true,
                detail: false,
                onClick: _ctx.emailSupport
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("settings-page.contact-support")) + " ", 1),
                  _createVNode(_component_font_awesome_icon, {
                    class: "item__icon",
                    slot: "end",
                    icon: ['fas', 'chevron-right']
                  })
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ion_item, {
                class: "item",
                button: true,
                detail: false,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.browserOpen({ url: 'https://www.loopin.ai/terms' })))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("settings-page.terms")) + " ", 1),
                  _createVNode(_component_font_awesome_icon, {
                    class: "item__icon",
                    slot: "end",
                    icon: ['fas', 'chevron-right']
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                class: "item",
                button: true,
                detail: false,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.browserOpen({ url: 'https://www.loopin.ai/privacy' })))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("settings-page.privacy")) + " ", 1),
                  _createVNode(_component_font_awesome_icon, {
                    class: "item__icon",
                    slot: "end",
                    icon: ['fas', 'chevron-right']
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_button, {
              onClick: _ctx.logout,
              color: "dark",
              class: "logout-btn",
              expand: "block",
              fill: "clear"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  class: "logout-btn-icon",
                  slot: "start",
                  icon: ['fas', 'sign-out']
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.t("settings-page.logout")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}