import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f378c4ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-wrapper" }
const _hoisted_2 = { class: "scrollable-content" }
const _hoisted_3 = { class: "info" }
const _hoisted_4 = { class: "info__item" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "info__item-title" }
const _hoisted_7 = { class: "info__item" }
const _hoisted_8 = { class: "info__item-logo" }
const _hoisted_9 = ["src", "alt"]
const _hoisted_10 = { class: "info__item-title" }
const _hoisted_11 = { class: "meta" }
const _hoisted_12 = { class: "meta__item" }
const _hoisted_13 = { class: "meta__item-icon-wrap" }
const _hoisted_14 = { class: "meta__item-text" }
const _hoisted_15 = { class: "meta__item" }
const _hoisted_16 = { class: "meta__item-icon-wrap" }
const _hoisted_17 = { class: "meta__item-text" }
const _hoisted_18 = { class: "instructions" }
const _hoisted_19 = {
  key: 0,
  class: "instructions__header"
}
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { class: "instructions__header" }
const _hoisted_22 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_IonRippleEffect = _resolveComponent("IonRippleEffect")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        (_ctx.actionInfo)
          ? (_openBlock(), _createBlock(_component_Badge, {
              key: 0,
              color: _ctx.actionInfo.color
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.actionInfo.text), 1)
              ]),
              _: 1
            }, 8, ["color"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "close-btn ion-activatable",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeHandler && _ctx.closeHandler(...args)))
        }, [
          _createVNode(_component_IonRippleEffect),
          _createVNode(_component_font_awesome_icon, { icon: ['fas', 'times'] })
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { class: "text-selectable" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: _normalizeClass({
              'info__item-logo': true,
              'info__item-logo--icon': !_ctx.company.image,
            })
                }, [
                  (_ctx.company.image)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: _ctx.company.image,
                        alt: _ctx.company.title
                      }, null, 8, _hoisted_5))
                    : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                        key: 1,
                        class: "info__item-logo-icon",
                        icon: ['fas', 'image']
                      }))
                ], 2),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.company.title), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("img", {
                    src: _ctx.social.image,
                    alt: _ctx.social.title
                  }, null, 8, _hoisted_9)
                ]),
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.social.title), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_font_awesome_icon, {
                    class: "meta__item-icon",
                    icon: ['fas', 'calendar']
                  })
                ]),
                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.date), 1)
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_font_awesome_icon, {
                    class: "meta__item-icon",
                    icon: ['fas', 'leaf']
                  })
                ]),
                _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.action), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              (_ctx.contentTitle)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_ctx.contentTitle), 1))
                : _createCommentVNode("", true),
              (_ctx.instruction)
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.t("common.instructions")), 1),
                    _createElementVNode("div", {
                      class: "instructions__content",
                      innerHTML: _ctx.sanitizedInstruction
                    }, null, 8, _hoisted_22)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createVNode(_component_ion_button, {
            expand: "block",
            onClick: _ctx.goToDeal
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("common.go-to-deal")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    })
  ], 64))
}