import { computed } from 'vue'
import { useStore } from 'vuex'
import { storeKey } from '@/store'

export function useAuth() {
  const store = useStore(storeKey)

  const hasRole = (role: string) => computed(() => 
    store.getters['auth/hasRole'](role)
  )

  const isTalentAssistant = computed(() => 
    store.getters['auth/hasRole']('ROLE_TALENT_ASSISTANT')
  )

  return {
    hasRole,
    isTalentAssistant
  }
}
