<template>
  <ion-page>
    <ion-header class="header" v-if="hasDeals?.data !== false">
      <SearchInput
        class="header__search-input"
        :placeholder="t('common.search-deals') + '...'"
        :onChange="onSearchQueryChange"
      ></SearchInput>
      <div class="header__title">{{ t("common.deals") }}</div>
      <div class="tabbar">
        <div
          @click="openSlide(0)"
          :class="{
            tabbar__item: true,
            'tabbar__item--active': slides && slides.activeIndex === 0,
          }"
        >
          {{ t("common.current") }}
        </div>
        <div
          @click="openSlide(1)"
          :class="{
            tabbar__item: true,
            'tabbar__item--active': slides && slides.activeIndex === 1,
          }"
        >
          {{ t("common.passed") }}
        </div>
      </div>
    </ion-header>
    <ion-content class="content">
      <no-deals-placeholder v-if="hasDeals?.data === false" />
      <template v-else>
        <swiper class="loopin-swiper" @swiper="setSwiperInstance">
          <swiper-slide class="slide">
            <deals-list
              :searchQuery="searchQuery"
              :params="{ [`hasFutureDeliverables`]: 'true' }"
              :showComission="showComission"
              @amountClick="switchShowComission"
              @dealClick="goToDetails"
            ></deals-list>
          </swiper-slide>
          <swiper-slide class="slide">
            <deals-list
              :searchQuery="searchQuery"
              :params="{ [`hasFutureDeliverables`]: 'false' }"
              :showComission="showComission"
              @amountClick="switchShowComission"
              @dealClick="goToDetails"
            ></deals-list>
          </swiper-slide>
        </swiper>
      </template>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonHeader, IonPage, isPlatform } from "@ionic/vue";
import SearchInput from "@/components/SearchInput.vue";
import { defineComponent, ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Swiper as SwiperType } from "swiper";
import { useRouter } from "vue-router";
import { Deal } from "@/types/deals";
import { useUserTalentHasDeals } from "@/hooks/talent";
import NoDealsPlaceholder from "@/components/NoDealsPlaceholder.vue";
import { useI18n } from "vue-i18n";
import DealsList from "@/components/DealsList.vue";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { DateTime } from "luxon";


export default defineComponent({
  name: "DealsPage",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    SearchInput,
    Swiper,
    SwiperSlide,
    NoDealsPlaceholder,
    DealsList,
  },
  methods: {
    openSlide(slide: number) {
      if (!this.slides) {
        return;
      }
      this.slides.slideTo(slide);
    },
  },
  setup() {
    const { t } = useI18n();
    const hasDeals = useUserTalentHasDeals();
    const router = useRouter();
    const slides = ref<SwiperType>();
    const searchQuery = ref<string>();
    const setSwiperInstance = (swiper: SwiperType) => {
      slides.value = swiper;
    };
    const showComission = ref<boolean>(false);      
    return {
      t,
      hasDeals,      
      showComission,
      switchShowComission: () => {
        showComission.value = !showComission.value;
        if (isPlatform("capacitor") && isPlatform("ios")) {
          Haptics.impact({ style: ImpactStyle.Light });
        }
      },
      slides,
      todayISODate: DateTime.now().startOf("day").toISO(),
      setSwiperInstance,
      searchQuery,
      onSearchQueryChange: (query: string) => {
        searchQuery.value = query ? query : undefined;
      },
      goToDetails: (deal: Deal) => {
        router.push(`/tabs/deals/details/${deal["@id"].split("/")[2]}`);
      },
    };
  },
});
</script>

<style scoped>
.header {
  padding: calc(var(--ion-safe-area-top) + 24px) 20px 16px 20px;
}
.header__search-input {
  margin-bottom: 24px;
}
.header__title {
  font-size: 24px;
  line-height: 26px;
  font-weight: bold;
  margin-bottom: 18px;
}
.tabbar {
  display: flex;
}
.tabbar__item {
  margin-right: 18px;
  font-weight: bold;
  line-height: 20px;
  opacity: 0.5;
  overflow: hidden;
  position: relative;
  padding: 0 2px;
  border-radius: 3px;
}
.tabbar__item--active {
  opacity: 1;
}
.loopin-swiper {
  height: 100%;
}
.content {
  --overflow: hidden;
}
.slide {
  display: block;
  text-align: left;
  overflow: hidden;
}
</style>