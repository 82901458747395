export enum SocialMedia {
  facebook = "Facebook",
  youtube = "Youtube",
  tiktok = "Tiktok",
  instagram = "Instagram",
  pinterest = "Pinterest",
  podcasts = "Podcasts",
  snapchat = "Snapchat",
  twitch = "Twitch",
  twitter = "Twitter",
  x = "X",
  whatsapp = "Whatsapp",
}

export enum AppColor {
  red = "red",
  pink = "pink",
  orange = "orange",
  green = "green",
  blue = "blue",
  yellow = "yellow",
  gray = "gray"
}
