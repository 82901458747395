import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-190f20b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = {
  key: 0,
  class: "loopin-no-permissions-warning"
}
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { style: {"margin-bottom":"15px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonRippleEffect = _resolveComponent("IonRippleEffect")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, "🔔 " + _toDisplayString(_ctx.t('notifications-modal.title')), 1),
        _createElementVNode("div", {
          class: "close-btn ion-activatable",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeHandler && _ctx.closeHandler(...args)))
        }, [
          _createVNode(_component_IonRippleEffect),
          _createVNode(_component_font_awesome_icon, { icon: ['fas', 'times'] })
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        (_ctx.hasPermissions === false)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.t("error-messages.no-notifications-permissions")), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, _toDisplayString(_ctx.t('notifications-modal.content[0]')), 1),
          _createElementVNode("div", null, _toDisplayString(_ctx.t('notifications-modal.content[1]')), 1),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, _toDisplayString(_ctx.t('notifications-modal.list[0]')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.t('notifications-modal.list[1]')), 1)
          ]),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('notifications-modal.content[2]')), 1),
          _createVNode(_component_ion_toggle, {
            disabled: _ctx.hasPermissions === false,
            onIonChange: _ctx.enableNotificationsChange,
            checked: _ctx.notificationsInfo?.enabled
          }, null, 8, ["disabled", "onIonChange", "checked"])
        ])
      ]),
      _: 1
    })
  ], 64))
}