// src/store/modules/auth.ts

import { Module } from 'vuex';
import { RootState } from '@/store';
import { Preferences } from '@capacitor/preferences';
import { jwtDecode } from "jwt-decode";

interface AuthState {
  token: string | null;
  userRoles: string[];
}

const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state: () => ({
    token: null,
    userRoles: [],
  }),
  mutations: {
    SET_TOKEN(state, token: string) {
      state.token = token;
    },
    SET_USER_ROLES(state, roles: string[]) {
      state.userRoles = roles;
    },
  },
  actions: {
    async initializeAuth({ commit, dispatch }) {
      const { value } = await Preferences.get({ key: 'loopin:authData' });
      if (value) {
        const authData = JSON.parse(value);
        commit('SET_TOKEN', authData.token);
        dispatch('decodeToken');
      }
    },
    decodeToken({ state, commit }) {
      if (state.token) {
        try {
          const decoded: any = jwtDecode(state.token);
          const roles = decoded.user?.roles || [];
          commit('SET_USER_ROLES', roles);
        } catch (error) {
          console.error('Error decoding JWT:', error);
          commit('SET_USER_ROLES', []);
        }
      } else {
        commit('SET_USER_ROLES', []);
      }
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    userRoles: (state) => state.userRoles,
    hasRole: (state) => (role: string) => state.userRoles.includes(role),
  },
};

export default auth;
