<template>
  <div>
    <LineChart ref="lineRef" :height="250" :chartData="chartData" :options="options" :plugins="plugins" />
  </div>
</template>

<script lang="ts">
import { LineChart } from "vue-chart-3";
import { defineComponent, ref, Ref, computed, PropType } from "vue";
import { ChartOptions, ChartData } from "chart.js";
import { formatPrice } from "@/utils";
import { DEFAULT_CURRENCY } from "@/utils/consts";

export default defineComponent({
  name: "line-chart",
  components: { LineChart },
  props: {
    dataSet: {
      type: Object as PropType<{
        labels: string[];
        data: number[];
      }>,
      required: true,
    },
  },
  setup(props, context) {

    interface LineChartInstance {
      canvasRef?: HTMLCanvasElement;
      // ... other properties or methods you want to access
    }
    const lineRef: Ref<LineChartInstance | null> = ref(null);
    let lastEvent = "";
    //const lineRef = ref<ExtractComponentData<typeof LineChart>>();
    const plugins = [
      {
        id: "drawLine",
        afterEvent(_: any, args: any) {
          lastEvent = args.event.type;
          if (
            [
              "click",
              "mouseout",
              "mouseup",
              "touchend",
              "touchcancel",
            ].includes(args.event.type)
          ) {
            context.emit("hoverEnd");
          }
        },
        afterDraw: (chart: any) => {
          if (
            [
              "click",
              "mouseout",
              "mouseup",
              "touchend",
              "touchcancel",
            ].includes(lastEvent) ||
            !chart.tooltip._active ||
            !chart.tooltip._active.length
          ) {
            return;
          }
          const activePoint = chart.tooltip._active[0];
          const ctx = chart.ctx;
          const x = activePoint.element.x;
          const topY = chart.scales.y.top;
          const bottomY = chart.scales.y.bottom;

          context.emit("hover", {
            value: props.dataSet.data[activePoint.index],
            label: props.dataSet.labels[activePoint.index],
          });
          // Render line on canvas
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, bottomY);
          ctx.lineTo(x, topY);
          ctx.lineWidth = 2;
          ctx.strokeStyle = "#ff65d1";
          ctx.stroke();
          ctx.restore();
        },
      },
    ];
    const ctx = document.createElement("canvas").getContext("2d");
    let gradient: CanvasGradient | undefined = undefined;
    if (ctx) {
      gradient = ctx.createLinearGradient(0, 0, 0, 200);
      gradient.addColorStop(0, "rgba(123, 97, 255, 0.35)");
      gradient.addColorStop(1, "rgba(0, 0, 0, 0.02)");
    }

    document.addEventListener("click", (e: any) => {
      if (!lineRef.value?.canvasRef) {
        return;
      }
      const $canvas = lineRef.value.canvasRef;
      if (e.target.id !== $canvas.id) {
        $canvas.dispatchEvent(new Event("mouseout"));
      }
    });

    const options = ref<ChartOptions<"line">>({
      responsive: true,
      interaction: {
        mode: "nearest",
        intersect: false,
      },
      events: [
        "mousemove",
        "mouseout",
        "click",
        "touchstart",
        "touchmove",
        "touchend",
      ],
      scales: {
        y: {
          grid: {
            color: "#32343B",
            lineWidth: 0.5,
            drawBorder: false,
          },
          afterFit: (s) => {
            s.width = 60;
          },
          ticks: {
            color: "#32343B",
            padding: 12,
            maxTicksLimit: 6,
            autoSkip: true,
            stepSize: 100,
            callback: (val) => {
              const amount = typeof val === "string" ? parseFloat(val) : val;
              return `${formatPrice(
                Math.round(amount / 1000),
                DEFAULT_CURRENCY
              )}${amount > 0 ? " k" : ""}`;
            },
            precision: 0.1,
            font: {
              size: 11,
            },
          },
        },
        x: {
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      },
      elements: {
        point: {
          radius: 0,
        },
        line: {
          tension: 0.3,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          axis: "x",
        },
      },
    });
    const chartData = computed<ChartData<"doughnut">>(() => ({
      labels: props.dataSet?.labels,
      datasets: [
        {
          data: props.dataSet.data,
          backgroundColor: gradient,
          borderColor: "#7B61FF",
          borderWidth: 2,
          fill: true,
          borderRadius: 4,
          hoverBorderColor: "rgba(0,0,0,0)",
          hoverBackgroundColor: "rgba(0,0,0,0)",
        },
      ],
    }));
    return { chartData, lineRef, options, plugins };
  },
});
</script>

<style scoped></style>